<template>
    <router-view></router-view>
</template>

<script>
    import Navigation from "./partials/Navigation";

    export default {
        name: "Main",
        components: {
            Navigation
        }
    }
</script>

<style scoped>

</style>
